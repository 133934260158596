import React from "react";
// Customizable Area Start
import {
  Grid,
  Paper,
  Typography,
  Container,
} from "@material-ui/core";
import {
  termsBack
} from "./assets";
import EmailAccountLoginController from "./EmailAccountLoginController";
// Customizable Area End

export default class EmailAccountPrivacyPolicy extends EmailAccountLoginController {
  
  render() {
    // Customizable Area Start
    const { navigation } = this.props;
    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Container maxWidth="lg"
            style={{maxWidth: "81%"}}
          >
            <Grid item xs={12} sm={12} md={12}
              style={webStyle.backIconTitle}
            >
              <img
                style={webStyle.backIcon}
                src={termsBack}
                alt="Back Icon"
                height="32"
                width="32"
                onClick={() => navigation.navigate("EmailAccountLoginBlock")}
              />
              <Typography
                style={webStyle.privacyPolicyText}
                variant="subtitle1"
              >
                Privacy Policy
              </Typography>
            </Grid>
            <Container maxWidth="lg"
              style={webStyle.innerContainer}
            >
              <Grid item xs={12} sm={12} md={12}
                style={webStyle.innerGrid}
              >
                <Paper
                  style={webStyle.paper}
                  elevation={3}
                >
                  <Typography
                    style={webStyle.privacyPolicy}
                    variant="subtitle1"
                  >
                    <p><b>Privacy Statement:</b></p>
                    <p>
                      User's right to privacy is of paramount importance to Addax.
                      When you register to use the Website, Addax will ask you to
                      provide your name, organization, email address, and other related
                      information. Addax will use this information to provide you with
                      a more personalized online experience and, where applicable, to
                      process orders, as well as to keep track of what topics are of
                      interest to our users. Addax will not share this information with
                      any third party.
                    </p>
                    <p><b>Restriction on Use:</b></p>
                    <p>
                      User will not (i) violate the security of the Site nor attempt 
                      to gain unauthorized access to the Site, data, materials, 
                      information, computer systems or networks connected to any server 
                      associated with the Site, through hacking, password timing or 
                      any other means; (ii) take nor attempt any action that, in the 
                      sole discretion of Addax, imposes or may impose an unreasonable 
                      or disproportionately large burden on the Site or its 
                      infrastructure; (iii) use nor attempt to use any scraper, 
                      robot, bot, spider, data mining, computer code, or any other 
                      automated device, program, tool, algorithm, process or methodology
                      to access, index, acquire, copy, or monitor any portion of the 
                      Site, any data or content found on or accessed through the Site
                      without the prior written consent of Addax; (iv) forge headers
                      or otherwise manipulate identifiers in order to disguise the 
                      origin of any other content. 
                    </p>
                  </Typography>
                </Paper>
              </Grid>
            </Container>
          </Container>
        </Grid>
      </Grid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyle = {
  backIconTitle: {
    marginTop: 30,
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  privacyPolicyText: {
    fontSize: "22px",
    fontWeight: 'bold' as 'bold',
    color: '#341f7c',
    margin: "auto",
    padding: "0px 5%",
  },
  innerContainer: {
    maxWidth: "100%",
    height: "85vh",
    overflow:"auto",
    marginTop: 20
  },
  innerGrid: {
    minHeight: "100vh",
    paddingBottom: "25px",
  },
  backIcon: {
    cursor: 'pointer',
  },
  paper: {
    display: "flex",
    flexDirection: "row" as "row",
    marginTop: "10px"
  },
  privacyPolicy: {
    padding: '20px 30px 25px'
  },
};
// Customizable Area End