import React from "react";
// Customizable Area Start
import {
  img1,
  img2,
  dashboardImages,
  footerBackground,
  Marketplace,
  Dossiers,
  Events
} from "./assets";
import {
  Grid,
  Box,
  Typography,
  Container,
  Button,
  withStyles
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import LandingPageController from "./LandingPageController";
// Customizable Area End

class LandingPageCenter extends LandingPageController {

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    return (
      <div style={webStyle.mainContainer} id="insights">
        <Container maxWidth="lg" style={{maxWidth: "81%"}}>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={6} md={6}>
              <img
                style={{width: "100%", height: "auto"}}
                src={Marketplace} alt="Image"
                height="316"
                width="520"
              />
            </Grid>
            <Grid
              style={webStyle.container}
              item
              xs={12}
              sm={6}
              md={6}
            >
              <Box style={{
                display: 'flex',
                flexDirection: 'row' as 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
                <ArrowForwardIcon
                  style={{
                    height: "auto",
                    width: "1vw",
                    marginRight: "5px"
                  }}
                />
                <Typography
                  style={webStyle.chooseAddax}
                  variant="subtitle2"
                >
                  More About Addax
                </Typography>
              </Box>
              <Typography
                style={webStyle.textHeader}
                variant="h4"
              >
                Find and assess the right vendors
              </Typography>
              <Typography
                style={webStyle.textSubHeader}
                variant="subtitle2"
              >
                Addax is a third party advisor, providing independent research
                into the marketplace of tech and service providers.
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid
              style={webStyle.container2}
              item
              xs={12}
              sm={12}
              md={12}
            >
              <Box style={{
                display: 'flex',
                flexDirection: 'row' as 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
                <ArrowForwardIcon
                  style={{
                    height: "auto",
                    width: "1vw",
                    marginRight: "5px"
                  }}
                />
                <Typography
                  style={webStyle.chooseAddax}
                  variant="subtitle2"
                >
                  Why Choose Addax Solutions
                </Typography>
              </Box>
              <Typography
                style={webStyle.powerfulText}
                variant="h5"
                align="center"
              >
                Powerful, Simple and Analytics Tools<br />
                for Trusted Data
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={8}>
            <Grid
              style={webStyle.container}
              item
              xs={12}
              sm={6}
              md={6}
            >
              <Typography
                variant="h4"
                style={webStyle.makeConfident}
              >
                Make confident, data-driven decisions
              </Typography>
              <Typography
                variant="subtitle2"
                style={webStyle.textSubHeader}
              >
                Addax enables confidence and performance with benchmark data and
                best practice research, including tools and templates you can
                employ today.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <img
                style={{width: "100%", height: "auto"}}
                src={Dossiers}
                alt="Image"
                height="316"
                width="520"
              />
            </Grid>
          </Grid>

          <Grid container spacing={8}>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
            >
              <img
                src={Events}
                alt="Image"
                height="240"
                width="330"
                style={{ width: "100%", height: "auto"}}
              />
            </Grid>

            <Grid
              style={webStyle.container}
              item
              xs={12}
              sm={6}
              md={6}
            >
              <Typography
                variant="h4"
                style={webStyle.textHeader}
              >
                Anticipate risk to your digital platform
              </Typography>
              <Typography
                variant="subtitle2"
                style={webStyle.textSubHeader}
              >
                Addax provides strategic intelligence into disinformation and
                digital harms.
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid
              style={webStyle.container5}
              item
              xs={12}
              sm={12}
              md={12}
            >
              <Typography
                style={webStyle.avoidCost}
                variant="h5"
                gutterBottom
              >
                Avoid Cost, Risk, and Delays
              </Typography>
              <Typography
                style={webStyle.addaxHelps}
                variant="subtitle2"
                gutterBottom
              >
                Addax helps you take decisive action, optimize<br />
                vendor contracts, spend less on consulting,<br />
                and reduce enterprise risk.
              </Typography>
              <Button
                style={webStyle.becomeClient}
                variant="contained"
              >
                <a
                  style={webStyle.clientButton}
                  href="mailto:support@addax.ai">
                  Become a client
                </a>
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = (theme: any) => ({
  
});

const webStyle = {
  mainContainer: {
    paddingTop: "25px",
    backgroundImage: `url(${footerBackground})`,
    backgroundSize: '100% 100%',
    backgroundPosition: 'center',
  },
  keepReadingText: {
    display: "flex",
    alignItems: "center",
    color: "#6449D1",
    marginBottom: "25px"
  },
  container2: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column" as "column",
    alignItems: "center",
    margin: 28,
  },
  chooseAddax: {
    display: "flex",
    alignItems: "center",
    fontSize: "0.9vw",
  },
  powerfulText: {
    color: "#0B1144",
    fontSize: "2.5vw"
  },
  makeConfident: {
    color: "#0B1144",
    marginTop: "20px",
    fontSize: "2.5vw"
  },
  container5: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column" as "column",
    alignItems: "center",
    margin: 20,
  },
  avoidCost: {
    color: "#0B1144",
    margin: 15,
    fontSize: "2.5vw"
  },
  addaxHelps: {
    color: "#0B1144",
    fontSize: "1.35vw"
  },
  keepReading: {
    display: "flex",
    alignItems: "center",
    color: "#6449D1",
  },
  becomeClient: {
    backgroundColor: "#6449d1",
    color: "#fff",
    height: "43px",
    textTransform: "capitalize" as "capitalize",
    padding: "1.5vw",
    marginBottom: 10,
    marginTop: 15,
    fontSize: "0.9vw"
  },
  clientButton: {
    textDecoration: 'none', 
    color: "#fff"
  },
  moreAbout: {
    fontSize: 15,
    marginBottom: -3
  },
  container: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "space-evenly",
  },
  textHeader: {
    color: "#0B1144",
    fontSize: "2.5vw"
  },
  textSubHeader: {
    color: "#0B1144",
    fontSize: "1.35vw"
  },
}

export default withStyles(styles)(LandingPageCenter);
// Customizable Area End
