import React from "react";
// Customizable Area Start
import {
  Grid,
  Box,
  Typography,
  Container,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import CategoriessubcategoriesController from "./CategoriessubcategoriesController";
import CategoriesSideBar from "./CategoriesSideBar.web";
import SnackbarAlert from "../../../components/src/SnackbarAlert.web";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import moment from "moment";
// Customizable Area End

export default class RecentArticles extends CategoriessubcategoriesController {
  
  // Customizable Area Start
  navigate = async (id: any) => {
    await sessionStorage.setItem('article-id', id);
    this.props.navigation.navigate('ShowArticles');
  }
  // Customizable Area End

  render() {
    // Customizable Area 
    const { drawerWidth } = this.state;
    return (
      <Grid container>
        <CategoriesSideBar
          {...this.props}
          openDrawer={this.state.openDrawer}
          onClosed={() => this.setState({ openDrawer: !this.state.openDrawer })}
          drawerWidth={drawerWidth}
        />
        <Grid item xs={12} sm={12} md={12}>
          <Container
            style={
              this.state.openDrawer ?
              {marginLeft:`${drawerWidth}px`,
              maxWidth:`calc(100% - ${drawerWidth}px)`} :
              {maxWidth:'81%'}
            }
            maxWidth="lg"
          >
            <Typography
              style={webStyle.recentlyAdded}
              variant="subtitle1"
            >
              Recently Added
            </Typography>
            <Grid item xs={12} sm={12} md={12}
              style={{height: "76vh", overflow:"auto"}}
            >
              {this.state?.recentArticlesData && this.state?.recentArticlesData?.map((item: any) => {
                return (
                  <div key={item?.id}>
                    <Box
                      style={webStyle.innerContainer}
                      onClick={() => this.navigate(item?.id)}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <Box
                          style={webStyle.createdDateBox}
                        >
                          <Typography
                            style={webStyle.titleHeadingCreatedDate}
                          >
                            Created at
                          </Typography>
                          <Typography
                            style={webStyle.titleHeading}
                          >
                            {item?.fields?.Created ? moment(item?.fields?.Created).format("DD MMM") : null}{" "}
                            {item?.fields?.Created ? moment(item?.fields?.Created).format("YYYY") : null}
                          </Typography>
                        </Box>
                        <Box style={{ display: "contents" }}>
                          <Typography
                            style={webStyle.title}
                            variant="subtitle1"
                          >
                            {item?.fields?.Title ? item?.fields?.Title : null}
                          </Typography>
                          <Typography
                            style={webStyle.body}
                            variant="subtitle2"
                          >
                            {item?.fields?.Body ? item?.fields?.Body?.substring(0, 250) : null}
                            &nbsp;
                            <Typography
                              style={webStyle.readMore}
                              component="span"
                              variant="body1"
                            >
                              ..Read More
                            </Typography>
                          </Typography>
                        </Box>
                      </Grid>
                    </Box>
                    <hr style={webStyle.hrTag} />
                  </div>
                );
              })}
            </Grid>
            {this.state?.recentArticleLoading ?
              <Backdrop
                style={{ zIndex: 1000 + 1, color: "#341f7c" }}
                open={this.state?.recentArticleLoading}
              >
                <CircularProgress color="inherit" />
              </Backdrop> : null
            }
            {this.state?.tokenExpired === 'Token has Expired' ?
              <SnackbarAlert
                openSnackbar={this.state?.openSnackbar}
                handleCloseSnackbar={this.handleCloseSnackbar}
              />
              : null
            }
          </Container>
        </Grid>
      </Grid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  recentlyAdded: {
    marginTop: "100px",
    fontSize: "24px",
    fontWeight: 'bold' as 'bold',
    color: '#341f7c',
    marginBottom: "5px",
  },
  innerContainer: {
    marginTop: 20,
    width: "95%", 
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  createdDateBox: {
    float: "left" as "left",
    maxWidth: "100%",
    marginBottom: "10px",
    height: "140px",
    width: "205px",
    marginRight: "20px",
    borderRadius: "10px",
    borderLeft: "8px solid #341f7c",
    padding: "10px",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "space-evenly",
    alignItems: "center"
  },
  titleHeadingCreatedDate: {
    fontSize: "20px",
    fontWeight: 'bold' as 'bold',
    lineHeight: "27px",
    opacity: "0.5"
  },
  titleHeading: {
    fontSize: "20px",
    fontWeight: 'bold' as 'bold',
    lineHeight: "27px",
  },
  image: {
    float: "left" as "left",
    maxWidth: "100%",
    marginBottom: "10px",
    height: "140px",
    width: "205px",
    marginRight: "15px",
    borderRadius: "15px"
  },
  title: {
    fontSize: "20px",
    fontWeight: 'bold' as 'bold',
    lineHeight: "27px",
  },
  body: {
    fontSize: "16px",
    fontWeight: 400,
    marginTop: 10
  },
  readMore: {
    color: "#341f7c",
    fontWeight: "bold" as "bold",
  },
  hrTag: {
    width: '95%',
    border: "0.5px solid #341f7c",
    opacity: "0.08475167"
  }
};
// Customizable Area End