import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  Paper,
  Typography,
  Container,
  Backdrop,
  CircularProgress
} from "@material-ui/core";
import {
  backIcon,
} from "./assets";
import CategoriessubcategoriesController from "./CategoriessubcategoriesController";
import CategoriesSideBar from "./CategoriesSideBar.web";
import SnackbarAlert from "../../../components/src/SnackbarAlert.web";

// Customizable Area End

export default class ShowArticles extends CategoriessubcategoriesController {
  
  // Customizable Area Start
  async componentDidMount() {
    let id = await sessionStorage.getItem('article-id');
    if(id) {
      this.handleFullShowArticles(id);
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area 
    const { drawerWidth } = this.state;
    return (
      <div>
        <CategoriesSideBar
          {...this.props}
          openDrawer={this.state.openDrawer}
          onClosed={() => this.setState({ openDrawer: !this.state.openDrawer })}
          drawerWidth={drawerWidth}
        />
        <Container
          style={
            this.state.openDrawer ?
            {marginLeft:`${drawerWidth-15}px`,
            maxWidth:`calc(100% - ${drawerWidth}px)`} :
            {maxWidth:'83%'}
          }
          maxWidth="lg"
        >
          <Grid container>
            <Grid item xs={12} sm={12} md={12}
              style={webStyle.backIconTitle}
            >
              <img
                style={webStyle.backIcon}
                src={backIcon}
                alt="Back Icon"
                height="32"
                width="32"
                onClick={() => {
                  this.props.navigation.navigate("RecentArticles");
                  sessionStorage.removeItem("article-id");
                  sessionStorage.removeItem("marketplace-id");
                  sessionStorage.removeItem("event-id");
                  sessionStorage.removeItem("dossier-id");
                }}
              />
              <Typography
                style={webStyle.title}
                variant="subtitle1"
              >
                {this.state?.showFullArticleData?.fields?.Title ?
                this.state?.showFullArticleData?.fields?.Title :
                null}
              </Typography>
            </Grid>
            <Container maxWidth="lg"
              style={webStyle.innerContainer}
            >
              <Grid item xs={12} sm={12} md={12}
                style={webStyle.innerGrid}
              >
                <Paper
                  style={webStyle.paper}
                  elevation={3}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <div style={{padding: "20px 5px 0px 30px"}}>
                      <Typography
                        style={webStyle.titleHeading}
                        variant="subtitle1"
                        component="span"
                      >
                        {this.state?.showFullArticleData?.fields?.Title ?
                        this.state?.showFullArticleData?.fields?.Title:
                        null}
                      </Typography>
                    </div>
                    <Typography
                      style={webStyle.subtitle}
                      variant="subtitle2"
                    >
                      {this.state?.showFullArticleData?.fields?.Body ?
                      this.state?.showFullArticleData?.fields?.Body :
                      null}
                    </Typography>
                  </Grid>
                </Paper>
              </Grid>
            </Container>
          </Grid>
          {this.state?.showArticlesLoading ?
            <Backdrop
              style={{ zIndex: 1000 + 1, color: "#341f7c" }}
              open={this.state?.showArticlesLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop> : null
          }
          {this.state?.tokenExpired === 'Token has Expired' ?
            <SnackbarAlert
              openSnackbar={this.state?.openSnackbar}
              handleCloseSnackbar={this.handleCloseSnackbar}
            />
            : null
          }
        </Container>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  backIconTitle: {
    marginTop: "100px",
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  backIcon: {
    cursor: 'pointer',
    marginLeft: "15px"
  },
  title: {
    fontSize: "22px",
    fontWeight: 'bold' as 'bold',
    color: '#341f7c',
    margin: "auto",
    padding: "0px 5%",
  },
  innerContainer: {
    maxWidth: "100%",
    height: "85vh",
    overflow:"auto",
    marginTop: 20
  },
  innerGrid: {
    minHeight: "100vh",
    paddingBottom: "25px",
  },
  paper: {
    display: "flex",
    flexDirection: "row" as "row",
    marginTop: "10px"
  },
  image: {
    float: "left" as "left",
    maxWidth: "100%",
    height: "410px",
    width: "600px",
    borderRadius: "15px",
    margin: "25px 20px 15px 26px",
  },
  titleHeading: {
    fontSize: "20px",
    fontWeight: 'bold' as 'bold',
  },
  subtitle: {
    margin: "10px 30px 30px 30px",
    fontSize: "16px",
    fontWeight: 400,
  },
};
// Customizable Area End