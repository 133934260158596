import React from "react";
// Customizable Area Start
import {
  Grid,
  Paper,
  Typography,
  Container,
  Backdrop,
  CircularProgress
} from "@material-ui/core";
import {
  backIcon,
} from "./assets";
import CategoriessubcategoriesController from "./CategoriessubcategoriesController";
import CategoriesSideBar from "./CategoriesSideBar.web";
import SnackbarAlert from "../../../components/src/SnackbarAlert.web";

// Customizable Area End

export default class ShowMarketPlace extends CategoriessubcategoriesController {
  
  // Customizable Area Start
  async componentDidMount() {
    let id = await sessionStorage.getItem('marketplace-id');
    if(id) {
      this.handleShowMarketPlace(id);
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area 
    const { drawerWidth } = this.state;
    return (
      <div>
        <CategoriesSideBar
          {...this.props}
          openDrawer={this.state.openDrawer}
          onClosed={() => this.setState({ openDrawer: !this.state.openDrawer })}
          drawerWidth={drawerWidth}
        />
        <Container
          style={
            this.state.openDrawer ?
            {marginLeft:`${drawerWidth-15}px`,
            maxWidth:`calc(100% - ${drawerWidth}px)`} :
            {maxWidth:'83%'}
          }
          maxWidth="lg"
        >
          {!this.state?.showMarketPlaceLoading ?
            <Grid container
              style={webStyle.gridContainer}
            >
              <Grid item xs={12} sm={12} md={12}
                style={webStyle.backIconTitle}
              >
                <img
                  style={webStyle.backIcon}
                  src={backIcon}
                  alt="Back Icon"
                  height="32"
                  width="32"
                  onClick={() => {
                    this.props.navigation.navigate("MarketPlace");
                    sessionStorage.removeItem("article-id");
                    sessionStorage.removeItem("marketplace-id");
                    sessionStorage.removeItem("event-id");
                    sessionStorage.removeItem("dossier-id");
                  }}
                />
                <Typography
                  style={webStyle.title}
                  variant="subtitle1"
                >
                  {this.state?.showFullMarketPlaceData?.fields?.Org ?
                  this.state?.showFullMarketPlaceData?.fields?.Org :
                  null}
                </Typography>
              </Grid>
              <Container maxWidth="lg"
                style={webStyle.innerContainer}
              >
                <Grid item xs={12} sm={12} md={12}
                  style={{
                    minHeight: "100vh",
                    paddingBottom: "25px",
                  }}
                >
                  <Paper
                    style={webStyle.paper}
                    elevation={3}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <img
                        style={webStyle.logo}
                        src={
                          this.state?.showFullMarketPlaceData?.fields?.Logo ?
                          this.state?.showFullMarketPlaceData?.fields?.Logo[0].url :
                          null
                        }
                        alt="Logo"
                        height="173"
                        width="205"
                      />
                      <div style={webStyle.nameTextContainer}>
                        <Typography
                          variant="subtitle1"
                          component="span"
                          style={webStyle.nameText}
                        >
                          {this.state?.showFullMarketPlaceData?.fields?.Org ?
                          this.state?.showFullMarketPlaceData?.fields?.Org:
                          null}
                        </Typography>
                      </div>
                      <Typography
                        style={webStyle.fields}
                        variant="subtitle2"
                      >
                        {this.state?.showFullMarketPlaceData?.fields?.Synopsis_of_the_Org ?
                        this.state?.showFullMarketPlaceData?.fields?.Synopsis_of_the_Org :
                        null}
                      </Typography>
                      {this.state?.showFullMarketPlaceData?.fields?.Revenue ?
                        <Typography
                        style={webStyle.fields}
                          variant="subtitle2"
                        >
                          <b>Revenue : </b>{this.state?.showFullMarketPlaceData?.fields?.Revenue ?
                          this.state?.showFullMarketPlaceData?.fields?.Revenue :
                          null}
                        </Typography> : 
                      null}
                      {this.state?.showFullMarketPlaceData?.fields?.Org_Email ?
                        <Typography
                        style={webStyle.fields}
                          variant="subtitle2"
                        >
                          <b>Org Email : </b>{this.state?.showFullMarketPlaceData?.fields?.Org_Email ?
                          this.state?.showFullMarketPlaceData?.fields?.Org_Email :
                          null}
                        </Typography> : 
                      null}
                      {this.state?.showFullMarketPlaceData?.fields?.Org_Website ?
                        <Typography
                        style={webStyle.fields}
                          variant="subtitle2"
                        >
                          <b>Org Website : </b>
                          <a href={`${this.state?.showFullMarketPlaceData?.fields?.Org_Website ?
                            this.state?.showFullMarketPlaceData?.fields?.Org_Website :
                            null}`} target="_blank"
                          >
                            {this.state?.showFullMarketPlaceData?.fields?.Org_Website ?
                            this.state?.showFullMarketPlaceData?.fields?.Org_Website :
                            null}
                          </a>
                        </Typography> : 
                      null}
                      {this.state?.showFullMarketPlaceData?.fields?.Offering_1 ?
                        <Typography
                        style={webStyle.fields}
                          variant="subtitle2"
                        >
                          <b>Offering 1 : </b>{this.state?.showFullMarketPlaceData?.fields?.Offering_1 ?
                          this.state?.showFullMarketPlaceData?.fields?.Offering_1 :
                          null}
                        </Typography> : 
                      null}
                      {this.state?.showFullMarketPlaceData?.fields?.Offering_1_website ?
                        <Typography
                        style={webStyle.fields}
                          variant="subtitle2"
                        >
                          <b>Offering 1 Website : </b>
                          <a href={`${this.state?.showFullMarketPlaceData?.fields?.Offering_1_website ?
                            this.state?.showFullMarketPlaceData?.fields?.Offering_1_website :
                            null}`} target="_blank"
                          >
                            {this.state?.showFullMarketPlaceData?.fields?.Offering_1_website ?
                            this.state?.showFullMarketPlaceData?.fields?.Offering_1_website :
                            null}
                          </a>
                        </Typography> : 
                      null}
                      {this.state?.showFullMarketPlaceData?.fields?.Offering_2 ?
                        <Typography
                        style={webStyle.fields}
                          variant="subtitle2"
                        >
                          <b>Offering 2 : </b>{this.state?.showFullMarketPlaceData?.fields?.Offering_2 ?
                          this.state?.showFullMarketPlaceData?.fields?.Offering_2 :
                          null}
                        </Typography> : 
                      null}
                      {this.state?.showFullMarketPlaceData?.fields?.Offering_2_website ?
                        <Typography
                        style={webStyle.fields}
                          variant="subtitle2"
                        >
                          <b>Offering 2 Website : </b>
                          <a href={`${this.state?.showFullMarketPlaceData?.fields?.Offering_2_website ?
                            this.state?.showFullMarketPlaceData?.fields?.Offering_2_website :
                            null}`} target="_blank"
                          >
                            {this.state?.showFullMarketPlaceData?.fields?.Offering_2_website ?
                            this.state?.showFullMarketPlaceData?.fields?.Offering_2_website :
                            null}
                          </a>
                        </Typography> :
                      null}
                    </Grid>
                  </Paper>
                </Grid>
              </Container>
            </Grid> :
            <Backdrop
              style={{ zIndex: 1000 + 1, color: "#341f7c" }}
              open={this.state?.showMarketPlaceLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          }
          {this.state?.tokenExpired === 'Token has Expired' ?
            <SnackbarAlert
              openSnackbar={this.state?.openSnackbar}
              handleCloseSnackbar={this.handleCloseSnackbar}
            />
            : null
          }
        </Container>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  gridContainer: {
    marginTop: 10,
  },
  backIconTitle: {
    marginTop: "90px",
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  backIcon: {
    cursor: 'pointer',
    marginLeft: "15px"
  },
  title: {
    fontSize: "22px",
    fontWeight: 'bold' as 'bold',
    color: '#341f7c',
    margin: "auto",
    padding: "0px 5%",
  },
  innerContainer: {
    maxWidth: "100%",
    height: "85vh",
    overflow:"auto",
    marginTop: 20
  },
  paper: {
    display: "flex",
    flexDirection: "row" as "row",
    marginTop: "10px"
  },
  logo: {
    maxWidth: "100%",
    height: "173px",
    width: "205px",
    margin: "25px 20px 15px 26px",
  },
  nameTextContainer: {
    padding: "20px 5px 0px 30px"
  },
  nameText: {
    fontSize: "20px",
    fontWeight: 'bold' as 'bold',
  },
  fields: {
    margin: "10px 30px 30px 30px",
    fontSize: "20px",
    fontWeight: 400,
  }
};
// Customizable Area End