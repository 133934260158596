import React from "react";
// Customizable Area Start
import {
  Grid,
  Box,
  Button,
  TextField,
  Typography,
  Container,
  Backdrop,
  CircularProgress
} from "@material-ui/core";
import {
  backgroundImage,
  logoAddax
} from "./assets";
import { withStyles } from "@material-ui/core/styles";

const StyledTextFields = withStyles({
  root: {
    color: '#0b0b0b',
    fontFamily: 'AirbnbCerealApp-Bold',
    '& .MuiOutlinedInput-input': {
      padding: '15px 15px'
    },
    '& .MuiInputBase-root': {
      borderRadius: '10px',
      marginTop: 5
    }
  }
})(TextField);

import EmailAccountLoginController from "./EmailAccountLoginController";
// Customizable Area End

class EmailAccountResetPassword extends EmailAccountLoginController {
  
  render() {
    // Customizable Area Start
    const { newPassword, confirmNewPassword } = this.state?.passwordFormData;
    const { classes }: any = this.props;
    return (
      <Container maxWidth={false} style={webStyle.container}>
        <Grid container style={webStyle.mainWrapper}>
          <Grid item xs={12} sm={8} md={6} style={webStyle.mainInnerWrapper1}>
          <Box
            style={webStyle.addaxLogo}
          >
            <Typography
              className={classes.betaText}
              style={webStyle.betaText}
              variant="h4"
              component="div"
            >
              Beta
            </Typography>
            <img
              className={classes.logo}
              style={{width: '100%'}}
              src={logoAddax}
              alt="Logo"
              height="214"
              width="313"
            />
          </Box>
          </Grid>
          <Grid item xs={12} sm={8} md={6} style={webStyle.mainInnerWrapper2}>
            <Grid item xs={10}
              style={webStyle.formContainer}
            >
              <form onSubmit={this.handleResetPasswordSubmit}>
                <Box style={webStyle.loginFormHeading}>
                  <Typography
                    style={webStyle.resetPasswordText}
                    variant="h6"
                  >
                    Reset Password
                  </Typography>
                  <Typography
                    style={webStyle.pleaseChoose}
                    variant="body1"
                  >
                    Please Choose New Password for Future Login!
                  </Typography>
                </Box>
                <Box style={webStyle.loginFormEmail}>
                  <Typography
                    variant="subtitle1"
                  >
                    Choose New Password
                  </Typography>
                  <StyledTextFields
                    fullWidth
                    variant="outlined"
                    type="password"
                    placeholder="Enter New Password"
                    name="newPassword"
                    value={newPassword}
                    onChange={this.handleChangePasswords}
                    error={this.state?.isErrorResetPasswords?.newPassword ? true : false}
                  />
                  <div style={webStyle.errorNewPassword}>
                    {this.state?.isErrorResetPasswords?.newPassword}
                  </div>
                </Box>
                <Box style={webStyle.loginFormPassword}>
                  <Typography
                    variant="subtitle1"
                  >
                    Confirm New Password
                  </Typography>
                  <StyledTextFields
                    fullWidth
                    variant="outlined"
                    type="password"
                    placeholder="Confirm New Password"
                    name="confirmNewPassword"
                    value={confirmNewPassword}
                    onChange={this.handleChangePasswords}
                    error={this.state?.isErrorResetPasswords?.confirmNewPassword ? true : false}
                  />
                  <div style={webStyle.errorConfirmNewPassword}>
                    {this.state?.isErrorResetPasswords?.confirmNewPassword}
                  </div>
                </Box>
                <Box style={webStyle.resetButtonBox}>
                  <Button
                    style={webStyle.resetButton}
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Reset
                  </Button>
                </Box>
              </form>
            </Grid>
            {this.state?.resetLoading ?
              <Backdrop
                style={{ zIndex: 1000 + 1, color: "#341f7c" }}
                open={this.state?.resetLoading}
              >
                <CircularProgress color="inherit" />
              </Backdrop> : null
            }
          </Grid>
        </Grid>
      </Container>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = (theme: any) => ({
  logo: {
    [theme.breakpoints.down('sm')]: {
      height: '180px',
      width: '260px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '140px',
      width: '220px',
    }
  },
  betaText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '25px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px',
    }
  }
});

const webStyle = {
  container: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: '100% 100%',
    backgroundPosition: 'center',
  },
  mainWrapper: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
  },
  betaText: {
    color: 'white',
    marginRight: "20px"
  },
  mainInnerWrapper1: {
    minHeight: '20vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainInnerWrapper2: {
    minHeight: '60vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  addaxLogo: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginRight: "20%"
  },
  formContainer: {
    padding: '30px 40px',
    width: '440px',
    display: 'flex',
    flexDirection: 'column' as 'column',
    position: 'absolute' as 'absolute',
    borderRadius: '23px',
    background: '#ffffff',
    boxShadow: '12px 22px 24px rgba(52,31,124,0.09),-10px -8px 24px rgba(52,31,124,0.04)'
  },
  loginFormHeading: {
    paddingTop: 10,
  },
  resetPasswordText: {
    marginBottom: 5,
    fontWeight: 'bold' as 'bold',
    fontSize: "24px",
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'flex-start',
  },
  pleaseChoose: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'flex-start'
  },
  loginFormEmail: {
    marginTop: 15,
    width: '100%',
  },
  errorNewPassword: {
    marginTop: 10,
    color: "red",
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "flex-end",
    fontFamily: 'AirbnbCerealApp-Bold',
  },
  loginFormPassword: {
    marginTop: 15,
    width: '100%',
  },
  errorConfirmNewPassword: {
    marginTop: 10,
    marginBottom: 20,
    color: "red",
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "flex-end",
    fontFamily: 'AirbnbCerealApp-Bold',
  },
  resetButtonBox: {
    marginBottom: 10,
    marginTop: 25,
    width: '100%'
  },
  resetButton: {
    textTransform: 'capitalize' as 'capitalize',
    padding: '12px 0px',
    fontWeight: 'bold' as 'bold',
    background: '#341f7c',
    borderRadius: '10px',
  },
  forgotPwd: {
    marginTop: 15,
    marginBottom: 5,
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'flex-end',
    alignItems: 'end',
  },
  termsPrivacy: {
    textDecoration: "underline",
    cursor: "pointer",
    color: '#341f7c'
  }
};

export default withStyles(styles)(EmailAccountResetPassword);
// Customizable Area End