import React from "react";
// Customizable Area Start
import {
  Grid,
  Box,
  Typography,
  Container,
  Backdrop,
  CircularProgress
} from "@material-ui/core";
import {
  backIcon,
  profile2,
} from "./assets";
import CategoriessubcategoriesController from "./CategoriessubcategoriesController";
import CategoriesSideBar from "./CategoriesSideBar.web";
import SnackbarAlert from "../../../components/src/SnackbarAlert.web";
// Customizable Area End

export default class MarketPlace extends CategoriessubcategoriesController {
  
  // Customizable Area Start
   navigate = async (id: any) => {
    await sessionStorage.setItem('marketplace-id', id);
    this.props.navigation.navigate("ShowMarketPlace");
  }
  // Customizable Area End

  render() {
    // Customizable Area 
    const { drawerWidth } = this.state;
    return (
      <Grid container>
        <CategoriesSideBar
          {...this.props}
          openDrawer={this.state.openDrawer}
          onClosed={() => this.setState({ openDrawer: !this.state.openDrawer })}
          drawerWidth={drawerWidth}
        />
        <Grid item xs={12} sm={12} md={12}>
          <Container
            style={
              this.state.openDrawer ?
              {marginLeft:`${drawerWidth}px`,
              maxWidth:`calc(100% - ${drawerWidth}px)`} :
              {maxWidth:'81%'}
            }
            maxWidth="lg"
          >
            <Grid item xs={12} sm={12} md={12}
              style={webStyle.backIconTitle}
            >
              <img
                style={webStyle.backIcon}
                src={backIcon}
                alt="Back Icon"
                height="32"
                width="32"
                onClick={() => {
                  this.props.navigation.navigate("RecentArticles");
                  sessionStorage.removeItem("article-id");
                  sessionStorage.removeItem("marketplace-id");
                  sessionStorage.removeItem("event-id");
                  sessionStorage.removeItem("dossier-id");
                }}
              />
              <Typography
                style={webStyle.title}
                variant="subtitle1"
              >
                Marketplace
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}
              style={{height: "76vh", overflow:"auto"}}
            >
              {this.state?.marketPlaceData && this.state?.marketPlaceData?.map((item: any) => {
                return (
                  <div key={item?.id} style={{marginLeft: "10px"}}>
                    <Box
                      style={webStyle.innerContainer}
                      onClick={() => this.navigate(item?.id)}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <img
                          style={webStyle.logo}
                          src={
                            item?.fields?.Logo ?
                            item?.fields?.Logo[0]?.url :
                            null
                          }
                          alt="Logo"
                          height="173"
                          width="205"
                        />
                        <Box style={{ display: "contents" }}>
                          <Typography
                            variant="subtitle1"
                            style={webStyle.titleHeading}
                          >
                            {item?.fields?.Org ? item?.fields?.Org : null}
                          </Typography>
                          <Typography
                            style={webStyle.marketSegment}
                            variant="subtitle1"
                          >
                            <b>Market Segment :</b> {item?.fields?.Market_Segment ? item?.fields?.Market_Segment : null}
                          </Typography>
                          <Typography
                            style={webStyle.subtitle}
                            variant="subtitle2"
                          >
                            {item?.fields?.Synopsis_of_the_Org ?
                            item?.fields?.Synopsis_of_the_Org?.substring(0, 150) :
                            null}
                            &nbsp;
                            <Typography
                              style={webStyle.readMore}
                              component="span"
                              variant="body1"
                            >
                              ..Read More
                            </Typography>
                          </Typography>
                        </Box>
                      </Grid>
                    </Box>
                    <hr style={webStyle.hrTag} />
                  </div>
                );
              })}
            </Grid>
          </Container>
        </Grid>
        {this.state?.marketPlaceLoading ?
          <Backdrop
            style={{ zIndex: 1000 + 1, color: "#341f7c" }}
            open={this.state?.marketPlaceLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop> : null
        }
        {this.state?.tokenExpired === 'Token has Expired' ?
          <SnackbarAlert
            openSnackbar={this.state?.openSnackbar}
            handleCloseSnackbar={this.handleCloseSnackbar}
          />
          : null
        }
      </Grid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  backIconTitle: {
    marginTop: "100px",
    marginBottom: 5,
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  backIcon: {
    cursor: 'pointer'
  },
  title: {
    fontSize: "24px",
    fontWeight: 'bold' as 'bold',
    color: '#341f7c',
    margin: "auto"
  },
  innerContainer: {
    marginTop: 20,
    width: "95%", 
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  logo: {
    float: "left" as "left",
    maxWidth: "100%",
    marginBottom: "10px",
    height: "140px",
    width: "205px",
    marginRight: "20px",
    borderRadius: "10px",
    border: "1px solid black",
    padding: "10px",
  },
  titleHeading: {
    fontSize: "20px",
    fontWeight: 'bold' as 'bold',
    lineHeight: "27px",
  },
  marketSegment: {
    fontSize: "20px",
    fontWeight: 400,
    marginTop: 10
  },
  subtitle: {
    fontSize: "18px",
    fontWeight: 400,
    marginTop: 10
  },
  readMore: {
    color: "#341f7c",
    fontWeight: "bold" as "bold",
  },
  hrTag: {
    width: '95%',
    border: "0.5px solid #341f7c",
    opacity: "0.08475167"
  }
};
// Customizable Area End