import React from "react";
// Customizable Area Start
import {
  Grid,
  Box,
  Paper,
  Typography,
  Container,
} from "@material-ui/core";
import {
  termsBack
} from "./assets";
import EmailAccountLoginController from "./EmailAccountLoginController";
// Customizable Area End

export default class EmailAccountTermsConditions extends EmailAccountLoginController {
  
  render() {
    // Customizable Area Start
    const { navigation } = this.props;
    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Container maxWidth="lg"
            style={{maxWidth: "81%"}}
          >
            <Grid item xs={12} sm={12} md={12}
              style={webStyle.backIconText}
            >
              <img
                style={{cursor: 'pointer'}}
                src={termsBack}
                alt="Back Icon"
                height="32"
                width="32"
                onClick={() => navigation.navigate("EmailAccountLoginBlock")}
              />
              <Typography
                style={webStyle.termsOfServices}
                variant="subtitle1"
              >
                Terms of services
              </Typography>
            </Grid>
            <Container maxWidth="lg"
              style={webStyle.innerContainer}
            >
              <Grid item xs={12} sm={12} md={12}
                style={webStyle.innerGrid}
              >
                <Paper style={webStyle.paper}
                  elevation={3}>
                  <Typography
                    style={webStyle.termsConditions}
                    variant="subtitle1"
                  >
                    <p>
                      Welcome to Addax and thank you for partnering with us! You’ve joined a global community of experts and practitioners who are committed to digital trust & safety. We encourage you to take advantage of the content and features available on addax.ai and all Addax-owned websites (each, a “website”).
                    </p>
                    <p>
                      Before using the website, we encourage everyone to familiarize themselves and their teams with these non-negotiable terms of use. By continuing to use the website, you (the “user”) agree to be bound by these terms of use.
                    </p>
                    <p>
                      Before using the website, we encourage everyone to familiarize themselves and their teams with these non-negotiable terms of use. By continuing to use the website, you (the “user”) agree to be bound by these terms of use.
                    </p>
                    <p>
                      We encourage you to contact us if you have any questions about these terms or you would like to discuss licensing options for you and your team. The insights our clients leverage from Addax are transformative for their enterprises, customers, and stakeholders, and we wish to maximize your use of these same capabilities. 
                    </p>
                    <p>
                      <b>Website Content and Copyright:</b>
                    </p>
                    <p>
                      This Website is protected by U.S. and international copyright law and conventions as the sole and exclusive property of Addax. Addax grants to the User the right to access and use the Website, so long as such use is for internal information purposes. Further, the User may not alter, copy, disseminate, redistribute or republish any content or feature of this Website. User acknowledges that access to and use of this Website is subject to these TERMS OF USE and any expanded access or use must be approved in writing by Addax.
                    </p>
                    <p>
                      <b>Changes or Updates to the Website:</b>
                    </p>
                    <p>
                      Addax reserves the right to change, update or discontinue any aspect of this Website at any time without notice. Your continued use of the Website after any such change constitutes your agreement to these TERMS OF USE, as modified.
                    </p>
                    <p>
                      <b>Disclaimer of Warranties:</b>
                    </p>
                    <p>
                      Addax does not make any warranties, express or implied, including, without limitation, those of merchantability and fitness for a particular purpose, with respect to this Website. Although Addax takes reasonable steps to screen this Website for infection by viruses, worms, Trojan horses or other code manifesting contaminating or destructive properties, Addax cannot guarantee that it will be free of infection or that it will be available at any particular time or location.
                    </p>
                    <p>
                      <b>Accuracy of Information:</b>
                    </p>
                    <p>
                      While the information contained on this Website has been obtained from sources believed to be reliable, Addax disclaims all warranties as to the accuracy, completeness or adequacy of such information.
                    </p>
                    <p>
                      User assumes sole responsibility for the use it makes of this Website to achieve his/her intended results.
                    </p>
                    <p>
                      <b>Third-Party Links:</b>
                    </p>
                    <p>
                      This Website may contain links to other third-party websites, which are provided as additional resources for the convenience of Users. Addax does not endorse, sponsor or accept any responsibility for these third-party websites, User agrees to direct any concerns relating to these third-party websites to the relevant website administrator. 
                    </p>
                    <p>
                      <b>Limitation of Liability:</b>
                    </p>
                    <p>
                      In no event will Addax be liable for: (a) damages of any kind, including without limitation, direct, incidental or consequential damages (including, but not limited to, damages for lost profits Limitation of
                    </p>
                    <p>
                      <b>Liability: </b>
                    </p>
                    <p>
                      business interruption and loss of programs or information) arising out of the use of or inability to use this Website, or any information provided on this Website, or (b) any claim attributable to errors, omissions or other inaccuracies in the information provided on this Website.
                    </p>
                    <p>
                      <b>Termination:</b>
                    </p>
                    <p>
                      In accessing and using this Website, User agrees to comply with all applicable laws and agrees not to take any action that would compromise the security or viability of this Website. Addax may terminate User's access to this Website at any time for any reason. The terms hereunder regarding Disclaimer of Warranty, Accuracy of Information, Indemnification, and Third Party Rights shall survive termination. 
                    </p>
                    <p>
                      <b>Indemnification:</b>
                    </p>
                    <p>
                      User agrees to indemnify, defend and hold harmless Addax, its affiliates, licensors, and their respective officers, directors, employees and agents from and against all losses, expenses, damages and costs, including reasonable attorneys' fees, arising out of User's use/ misuse of this Website.
                    </p>
                    <p>
                      <b>Third-Party Rights: </b>
                    </p>
                    <p>
                      The terms hereunder regarding Disclaimer of Warranty, Limitation of Liability and Indemnification are for the benefit of Addax, and its licensors, employees and agents, each of whom shall have the right to assert and enforce those terms against a User.
                    </p>
                    <p>
                      <b>General Provisions: </b>
                    </p>
                    <p>
                      Any provision in any written communication received by Addax in connection with this Website which is inconsistent with, or adds to, these TERMS OF USE is void. If any term hereunder is determined by a court of competent jurisdiction to be invalid, all remaining terms will remain in full force and effect
                    </p>
                    <p>
                      <b>Governing Law: </b>
                    </p>
                    <p>
                      These TERMS OF USE and the resolution of any dispute arising hereunder shall all be governed and construed in accordance with the laws of the state of Connecticut, without regard to its conflicts of law principles. User consents to the jurisdiction of the courts of the state of Virginia.
                    </p>
                  </Typography>
                </Paper>
              </Grid>
            </Container>
          </Container>
        </Grid>
      </Grid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyle = {
  backIcon: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
  },
  backIconText: {
    marginTop: 30,
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  termsOfServices: {
    fontSize: "22px",
    fontWeight: 'bold' as 'bold',
    color: '#341f7c',
    margin: "auto",
    padding: "0px 5%",
  },
  innerContainer: {
    maxWidth: "100%",
    height: "85vh",
    overflow:"auto",
    marginTop: 20
  },
  innerGrid: {
    minHeight: "100vh",
    paddingBottom: "25px",
  },
  paper: {
    display: "flex",
    flexDirection: "row" as "row",
    marginTop: "10px"
  },
  termsConditions: {
    padding: '20px 30px 25px'
  },
};
// Customizable Area End