import React from "react";
// Customizable Area Start
import {
  Grid,
  Paper,
  Typography,
  Container,
  Backdrop,
  CircularProgress
} from "@material-ui/core";
import {
  backIcon,
} from "./assets";
import CategoriessubcategoriesController from "./CategoriessubcategoriesController";
import CategoriesSideBar from "./CategoriesSideBar.web";
import SnackbarAlert from "../../../components/src/SnackbarAlert.web";

// Customizable Area End

export default class ShowEvents extends CategoriessubcategoriesController {
  
  // Customizable Area Start
  async componentDidMount() {
    let id = await sessionStorage.getItem('event-id');
    if(id) {
      this.handleShowEvents(id);
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area 
    const { drawerWidth } = this.state;
    return (
      <div>
        <CategoriesSideBar
          {...this.props}
          openDrawer={this.state.openDrawer}
          onClosed={() => this.setState({ openDrawer: !this.state.openDrawer })}
          drawerWidth={drawerWidth}
        />
        <Container
          style={
            this.state.openDrawer ?
            {marginLeft:`${drawerWidth-15}px`,
            maxWidth:`calc(100% - ${drawerWidth}px)`} :
            {maxWidth:'83%'}
          }
          maxWidth="lg"
        >
          {!this.state?.showEventsLoading ?
            <Grid container>
              <Grid item xs={12} sm={12} md={12}
                style={webStyle.backIconTitle}
              >
                <img
                  style={webStyle.backIcon}
                  src={backIcon}
                  alt="Back Icon"
                  height="32"
                  width="32"
                  onClick={() => {
                    this.props.navigation.navigate("Events");
                    sessionStorage.removeItem("article-id");
                    sessionStorage.removeItem("marketplace-id");
                    sessionStorage.removeItem("event-id");
                    sessionStorage.removeItem("dossier-id");
                  }}
                />
                <Typography
                  style={webStyle.titleHeader}
                  variant="subtitle1"
                >
                  {this.state?.showEventsData?.fields?.Name_of_Event ?
                  this.state?.showEventsData?.fields?.Name_of_Event :
                  null}
                </Typography>
              </Grid>
              <Container maxWidth="lg"
                style={webStyle.innerContainer}
              >
                <Grid item xs={12} sm={12} md={12}
                  style={webStyle.innerGrid}
                >
                  <Paper
                    style={webStyle.paper}
                    elevation={3}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <div style={{padding: "20px 5px 0px 30px"}}>
                        <Typography
                          style={webStyle.title}
                          variant="subtitle1"
                          component="span"
                        >
                          {this.state?.showEventsData?.fields?.Name_of_Event ?
                          this.state?.showEventsData?.fields?.Name_of_Event:
                          null}
                        </Typography>
                      </div>
                      <Typography
                        style={webStyle.fields}
                        variant="subtitle2"
                      >
                        {this.state?.showEventsData?.fields?.Write_up_for_Event_Awareness_Email ?
                        this.state?.showEventsData?.fields?.Write_up_for_Event_Awareness_Email :
                        null}
                      </Typography>
                      {this.state?.showEventsData?.fields?.Damage_Done ?
                        <Typography
                          style={webStyle.fields}
                          variant="subtitle2"
                        >
                          <b>Damage Done : </b>{this.state?.showEventsData?.fields?.Damage_Done ?
                          this.state?.showEventsData?.fields?.Damage_Done :
                          null}
                        </Typography> : 
                      null}
                      {this.state?.showEventsData?.fields?.Current_Status ?
                        <Typography
                          style={webStyle.fields}
                          variant="subtitle2"
                        >
                          <b>Current Status : </b>{this.state?.showEventsData?.fields?.Current_Status ?
                          this.state?.showEventsData?.fields?.Current_Status :
                          null}
                        </Typography> : 
                      null}
                      {this.state?.showEventsData?.fields?.Type_of_Target ?
                        <Typography
                          style={webStyle.fields}
                          variant="subtitle2"
                        >
                          <b>Type Of Target : </b>
                          {this.state?.showEventsData?.fields?.Type_of_Target ?
                          this.state?.showEventsData?.fields?.Type_of_Target :
                          null}
                        </Typography> : 
                      null}
                      {this.state?.showEventsData?.fields?.TTP_Used ?
                        <Typography
                          style={webStyle.fields}
                          variant="subtitle2"
                        >
                          <b>TTP Used : </b>{this.state?.showEventsData?.fields?.TTP_Used ?
                          this.state?.showEventsData?.fields?.TTP_Used :
                          null}
                        </Typography> : 
                      null}
                      {this.state?.showEventsData?.fields?.Target ?
                        <Typography
                          style={webStyle.fields}
                          variant="subtitle2"
                        >
                          <b>Target : </b>
                          {this.state?.showEventsData?.fields?.Target ?
                          this.state?.showEventsData?.fields?.Target :
                          null}
                        </Typography> : 
                      null}
                      {this.state?.showEventsData?.fields?.Date_of_Event ?
                        <Typography
                          style={webStyle.fields}
                          variant="subtitle2"
                        >
                          <b>Date Of Event : </b>{new Date(this.state?.showFullMarketPlaceData?.fields?.Date_of_Event).toLocaleDateString() ?
                          new Date(this.state?.showEventsData?.fields?.Date_of_Event).toLocaleDateString() :
                          null}
                        </Typography> : 
                      null}
                      {this.state?.showEventsData?.fields?.Result ?
                        <Typography
                          style={webStyle.fields}
                          variant="subtitle2"
                        >
                          <b>Result : </b>
                          {this.state?.showEventsData?.fields?.Result ?
                          this.state?.showEventsData?.fields?.Result :
                          null}
                        </Typography> :
                      null}
                      {this.state?.showEventsData?.fields?.Sources ?
                        <Typography
                          style={webStyle.fields}
                          variant="subtitle2"
                        >
                          <b>Sources : </b>
                          <a
                            href={`${this.state?.href}`}
                            target="_blank"
                          >
                            {this.state?.showEventsData?.fields?.Sources ?
                            this.state?.showEventsData?.fields?.Sources.map((item: any) => <p onClick={() => this.setState({ href: item })}>{item}</p>) :
                            null}
                          </a>
                        </Typography> :
                      null}
                    </Grid>
                  </Paper>
                </Grid>
              </Container>
            </Grid> :
            <Backdrop
              style={{ zIndex: 1000 + 1, color: "#341f7c" }}
              open={this.state?.showEventsLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          }
          {this.state?.tokenExpired === 'Token has Expired' ?
            <SnackbarAlert
              openSnackbar={this.state?.openSnackbar}
              handleCloseSnackbar={this.handleCloseSnackbar}
            />
            : null
          }
        </Container>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  backIconTitle: {
    marginTop: "100px",
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  backIcon:{
    cursor: 'pointer',
    marginLeft: "15px"
  },
  titleHeader: {
    fontSize: "22px",
    fontWeight: 'bold' as 'bold',
    color: '#341f7c',
    margin: "auto",
    padding: "0px 5%",
  },
  innerContainer: {
    maxWidth: "100%",
    height: "85vh",
    overflow:"auto",
    marginTop: 20
  },
  innerGrid: {
    minHeight: "100vh",
    paddingBottom: "25px",
  },
  paper: {
    display: "flex",
    flexDirection: "row" as "row",
    marginTop: "10px",
    overflow:"overlay"
  },
  title: {
    fontSize: "20px",
    fontWeight: 'bold' as 'bold',
  },
  fields: {
    margin: "10px 30px 30px 30px",
    fontSize: "20px",
    fontWeight: 400,
  }
};
// Customizable Area End