import React from "react";
// Customizable Area Start
import {
  Grid,
  Box,
  Typography,
  Container,
  Button,
  withStyles
} from "@material-ui/core";
import {
  blueBackgroundImage,
  arrow,
  icon1,
  icon2,
  icon3,
  icon4,
} from "./assets";
import LandingPageController from "./LandingPageController";
// Customizable Area End

class LandingPageServicesPortfolio extends LandingPageController {
  
  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    const mockData = [
      {
        id: 1,
        image: icon1,
        title: "Marketplace",
        subtitle: "Analysis on hundreds of tech and services vendors in the digital trust & safety market.",
      },
      {
        id: 2,
        image: icon2,
        title: "Experts",
        subtitle: "Speak with experts to dive deep into their research or strategize new ideas.",
      },
      {
        id: 3,
        image: icon3,
        title: "Advice",
        subtitle: "Data and analysis into management best practices and emerging threats that you can implement.",
      },
      {
        id: 4,
        image: icon4,
        title: "Peers",
        subtitle: "Learn from your peers across government and industry through curated interactions.",
      },
    ];
    return (
      <div style={webStyle.container} id="services">
        <Container maxWidth="lg" style={{maxWidth: "81%"}}>
          <Grid container style={webStyle.mainContainer}>
            <Grid item xs={12} sm={12} md={12} style={webStyle.arrowIcon}>
              <img style={webStyle.convertArrow} src={arrow} alt="Logo" />
              <Typography
                style={webStyle.subtitle}
                variant="subtitle1"
              >
                Convert, Engage & Retain
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                style={webStyle.title}
                variant="h6"
              >
                Addax Services Portfolio
              </Typography>
            </Grid>
            <Grid container style={webStyle.rows} spacing={8}>
              {mockData.map((item: any, index: any) => (
                <Grid item xs={12} sm={6} md={3}
                  style={webStyle.innerContainer}
                  key={index}
                >
                  <img className={classes.icons} style={webStyle.icons} src={item.image} alt="Logo" />
                  <Typography
                    style={webStyle.rowsTitle}
                    variant="body1"
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    style={webStyle.rowsSubTitle}
                    variant="body1"
                  >
                    {item.subtitle}
                  </Typography>
                  <Box style={webStyle.arrowIconReadMore}>
                    <Typography
                      style={webStyle.readMore}
                      variant="body1"
                    >
                      Read More
                    </Typography>
                    <img
                      style={{
                        marginLeft: 10,
                        height: "auto",
                        width: "0.7vw"
                      }}
                      src={arrow}
                      alt="Logo"
                    />
                  </Box>
                </Grid>  
              ))}
            </Grid>
            <Grid item xs={12} sm={12} md={12} style={webStyle.clickHereGrid}>
              <Button
                style={webStyle.button2}
                variant="contained"
              >
                <a
                  style={webStyle.clientButton}
                  href="mailto:support@addax.ai">
                  Become a client
                </a>
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = (theme: any) => ({
  icons: {
    [theme.breakpoints.up("lg")]: {
      marginBottom: "20px" 
    },
    [theme.breakpoints.down("lg")]: {
      marginBottom: "10px" 
    },
  }
});

const webStyle = {
  container: {
    padding: "20px 0px 20px 0px",
    backgroundImage: `url(${blueBackgroundImage})`,
    backgroundSize: '100% 100%',
    backgroundPosition: 'center',
  },
  mainContainer: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
  },
  convertArrow: {
    marginRight: 8,
    color: "#989cb8",
    height: "auto",
    width: "0.7vw"
  },
  arrowIcon: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: "#989cb8",
  },
  subtitle: {
    fontSize: "0.9vw",
    fontWeight: 500,
    color: "#fff"
  },
  title: {
    fontSize: "2vw",
    fontWeight: 500,
    color: "#ffffff",
    marginTop: 20,
  },
  rows: {
    marginTop: "30px",
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'space-between',
    padding: "20px 0px 20px 0px",
  },
  innerContainer: {
    paddingRight: 20,
    width: "301px",
    display: "inline-block",
    justifyContent: "center",
  },
  icons: {
    height: "auto",
    width: "1.7vw",
  },
  rowsTitle: {
    color: "#ffffff",
    fontSize: "2vw",
    marginTop: "18px",
    lineHeight: "41px",
    letterSpacing: "-1.247648px",
  },
  rowsSubTitle: {
    color: "#989cb8",
    fontSize: "1.1vw",
    fontWeight: 400,
    marginTop: "20px",
  },
  arrowIconReadMore: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: "35px",
  },
  readMore: {
    fontSize: "0.9vw",
    fontWeight: 400,
    color: "#ffffff",
  },
  clickHereGrid: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: "40px",
  },
  clickHere: {
    textDecoration: 'underline',
    display: 'inline-block'
  },
  button2: {
    backgroundColor: "#6449d1",
    color: "#fff",
    height: "43px",
    textTransform: "capitalize" as "capitalize",
    padding: "1.5vw",
    marginBottom: 10,
    marginTop: 15,
    fontSize: "0.9vw"
  },
  clientButton: {
    textDecoration: 'none', 
    color: "#fff"
  },
};

export default  withStyles(styles)(LandingPageServicesPortfolio);
// Customizable Area End