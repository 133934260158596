import React from "react";
// Customizable Area Start
import {
  Grid,
  Box,
  Button,
  Typography,
  Container,
  TextField,
  InputAdornment,
  Backdrop,
  CircularProgress
} from "@material-ui/core";
import {
  back,
  expandIcon
} from "./assets";
import {
  List,
  ListItem,
  ListItemText,
  Collapse
} from '@material-ui/core';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { withStyles } from "@material-ui/core/styles";
import CategoriesSideBar from "../../categoriessubcategories/src/CategoriesSideBar.web";
import UserProfileBasicController from "./UserProfileBasicController";
import SnackbarAlert from "../../../components/src/SnackbarAlert.web";

const StyledDialog = withStyles({
  root: {
    fontFamily: "AirbnbCerealApp-Medium",
    '& .MuiPaper-root': {
      borderRadius: "16px",
      boxShadow: "0px 0px 30px rgba(0,0,0,0.06)",
    }
  }
})(Dialog);

const StyledListItemText = withStyles({
  root: {
    fontFamily: "AirbnbCerealApp-Medium",
    '& .MuiTypography-root': {
      color: "#341f7c",
      fontWeight: "bold",
    }
  }
})(ListItemText);

const StyledTextFields = withStyles({
  root: {
    color: '#0b0b0b',
    fontFamily: 'AirbnbCerealApp-Bold',
    '& .MuiOutlinedInput-input': {
      padding: '15px 15px'
    },
    '& .MuiInputBase-root': {
      borderRadius: '10px',
    }
  }
})(TextField);

// Customizable Area End

export default class UserProfileBasicBlock extends UserProfileBasicController {
  
  render() {
    // Customizable Area 
    const { drawerWidth } = this.state;
    return (
      <Grid container style={{overflowX: 'hidden'}}>
        <CategoriesSideBar
          {...this.props}
          openDrawer={this.state.openDrawer}
          onClosed={() => this.setState({ openDrawer: !this.state.openDrawer })}
          drawerWidth={drawerWidth}
        />
        <Grid item xs={12} sm={12} md={12}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              style={webStyle.profile}
              variant="subtitle1"
            >
              Profile
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}
            style={webStyle.gridContainer}
          >
            <Container maxWidth="sm"
              style={webStyle.container}
            >
              <Box>
                <List style={{marginTop: '30px', padding: 0, cursor: 'pointer'}}>
                  <ListItem
                    onClick={() => this.setState({
                      isErrorFullName: {},
                      collapseFullName: !this.state?.collapseFullName,
                      full_name: `${this.state?.profileData?.attributes?.first_name} ${this.state?.profileData?.attributes?.last_name}`
                    })}
                  >
                    <StyledListItemText primary="Full Name" />
                    <img
                      style={webStyle.expandImage}
                      src={expandIcon}
                      alt="Icon"
                      width="22.99"
                      height="22"
                    />
                  </ListItem>
                  <Collapse in={this.state?.collapseFullName} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem
                        style={webStyle.fullNameBox}
                      >
                        <StyledTextFields
                          fullWidth
                          variant="outlined"
                          placeholder="Enter your full name"
                          type="text"
                          value={this.state?.full_name ? this.state?.full_name : null}
                          onChange={(e) => this.setState({ full_name: e.target.value })}
                        />
                      </ListItem>
                      <div style={webStyle.fullNameErrorMessage}>
                        {this.state?.isErrorFullName?.fullName}
                      </div>
                    </List>
                  </Collapse>
                  <hr style={webStyle.hrTag} />
                </List>
              </Box>
              <Box>
                <List style={webStyle.list}>
                  <ListItem
                    onClick={() => this.setState({ collapseEmail: !this.state?.collapseEmail })}
                  >
                    <StyledListItemText primary="Email" />
                    <img
                      style={webStyle.expandImage}
                      src={expandIcon}
                      alt="Icon"
                      width="22.99"
                      height="22"
                    />
                  </ListItem>
                  <Collapse in={this.state?.collapseEmail} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding style={{marginBottom: "10px"}}>
                      <ListItem
                        style={{
                          height: "35px",
                          cursor: 'pointer',
                          marginTop: "10px",
                          marginBottom: "20px",
                        }}>
                        <StyledTextFields
                          fullWidth
                          variant="outlined"
                          placeholder="Enter your email"
                          type="email"
                          value={this.state?.profileData?.attributes?.email ?
                            this.state?.profileData?.attributes?.email : null}
                        />
                      </ListItem>
                    </List>
                  </Collapse>
                  <hr style={webStyle.hrTag} />
                </List>
              </Box>
              <Box onClick={() => this.setState({ openResetPassword: true })}>
                <List style={webStyle.list}>
                  <ListItem>
                    <StyledListItemText primary="Reset Password" />
                  </ListItem>
                  <hr style={webStyle.hrTag} />
                </List>
              </Box>
              <Box onClick={() => this.setState({ openDeactivateAccount: true })}>
                <List style={webStyle.list}>
                  <ListItem>
                    <StyledListItemText primary="Deactivate account" />
                  </ListItem>                    
                  <hr style={webStyle.hrTag} />
                </List>
              </Box>
              <Box>
                <List style={webStyle.list}>
                  <ListItem
                    onClick={() => this.props.navigation.navigate("TermsConditions")}
                  >
                    <StyledListItemText primary="Terms and Conditions" />
                  </ListItem>
                  <hr style={webStyle.hrTag} />
                </List>
              </Box>
              <Box>
                <List style={webStyle.list}>
                  <ListItem
                     onClick={() => this.setState({ openLogoutModal: true })}
                  >
                    <StyledListItemText primary="Logout" />
                  </ListItem>
                </List>
              </Box>
              <Box style={webStyle.saveButtonContainer}>
                <Button
                  style={webStyle.saveButton}
                  variant="contained"
                  color="primary"
                  onClick={this.handleSaveProfileData}
                >
                  Save
                </Button>
              </Box>
              <StyledDialog
                open={this.state?.openLogoutModal}
                onClose={() => this.setState({ openLogoutModal: false })}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <Box style={{ padding: 10 }}>
                  <DialogContent>
                    <DialogContentText
                      id="alert-dialog-description"
                      style={webStyle.deactivateConfirmation}  
                    >
                      Are you sure want to Logout?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions
                    style={webStyle.deactivateButtons}>
                    <Button
                      style={webStyle.noButton}
                      onClick={() => this.setState({ openLogoutModal: false })}
                      autoFocus
                    >
                      No
                    </Button>
                    <Button
                      style={webStyle.yesButton}
                      onClick={() => {this.logout(); this.setState({ openLogoutModal: false })}}
                      autoFocus
                    >
                      Yes
                    </Button>
                  </DialogActions>
                </Box>
              </StyledDialog>
              <StyledDialog
                open={this.state?.openDeactivateAccount}
                onClose={() => this.setState({ openDeactivateAccount: false })}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <Box style={{ padding: 10 }}>
                  <DialogContent>
                    <DialogContentText
                      id="alert-dialog-description"
                      style={webStyle.deactivateConfirmation}  
                    >
                      Are you sure want to Deactivate your account?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions
                    style={webStyle.deactivateButtons}>
                    <Button
                      style={webStyle.noButton}
                      onClick={() => this.setState({ openDeactivateAccount: false })}
                      autoFocus
                    >
                      No
                    </Button>
                    <Button
                      style={webStyle.yesButton}
                      onClick={this.handleDeactivateAccount}
                      autoFocus
                    >
                      {this.state?.deactivateAccountLoading ?
                        <CircularProgress size={26} color="inherit" />
                      : "Yes"}
                    </Button>
                  </DialogActions>
                </Box>
              </StyledDialog>
              <StyledDialog
                open={this.state?.openResetPassword}
                onClose={() => this.setState({ openResetPassword: false })}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <Box style={webStyle.resetPasswordBox}>
                  <DialogContent>
                    <DialogContentText
                      id="alert-dialog-description"
                    >
                      <Grid item xs={12} sm={12} md={12}
                        style={webStyle.backIconTitle}>
                          <img
                            style={webStyle.backIcon}
                            src={back}
                            alt="Back Icon"
                            height="25"
                            width="25"
                            onClick={() => this.setState({
                                openResetPassword: false,
                                resetPasswordLoading: false,
                                checkValidPassword: false,
                                current_password: "",
                                new_password: "",
                                new_password_confirmation: "",
                                currentPasswordError: "",
                                newPasswordError: "",
                                newPasswordConfirmationError: "",
                              })}
                          />
                        <Typography
                          style={webStyle.resetPasswordText}
                        >
                          Reset Password
                        </Typography>
                      </Grid>
                      <StyledTextFields
                        style={{marginTop: 15}}
                        fullWidth
                        variant="outlined"
                        placeholder="Current password"
                        type="password"
                        value={this.state?.current_password}
                        onBlur={() => {
                          this.checkCurrentPassword();
                          this.setState({
                            focus: false,
                            checkCurrentPasswordLoading: true
                          });
                        }}
                        onChange={(e) => {
                            this.setState({ current_password: e.target.value })
                            this.setState({ focus: true });
                          }
                        }
                        InputProps={{
                          endAdornment: (
                            <>
                              <InputAdornment position="end">
                                {this.state?.checkValidPassword === true
                                ? <CheckIcon style={{color: "#341f7c"}} />
                                : null}
                              </InputAdornment>
                              <InputAdornment position="end">
                                {this.state?.checkValidPassword
                                !== true
                                &&
                                this.state?.checkCurrentPasswordLoading
                                ? <CircularProgress style={{color: "#341f7c"}} size={26} color="inherit" />
                                : null}
                              </InputAdornment>
                            </>
                          ),
                        }}
                      />
                      <div style={webStyle.passwordErrorMessage}>
                        {this.state?.currentPasswordError}
                      </div>
                      <StyledTextFields
                        style={{marginTop: 20}}
                        fullWidth
                        variant="outlined"
                        placeholder="New password"
                        type="password"
                        value={this.state?.new_password}
                        onChange={(e) => this.setState({ new_password: e.target.value })}
                      />
                      <div style={webStyle.passwordErrorMessage}>
                        {this.state?.newPasswordError}
                      </div>
                      <StyledTextFields
                        style={{marginTop: 20}}
                        fullWidth
                        variant="outlined"
                        placeholder="Confirm password"
                        type="password"
                        value={this.state?.new_password_confirmation}
                        onChange={(e) => this.setState({ new_password_confirmation: e.target.value })}
                      />
                      <div style={webStyle.passwordErrorMessage}>
                        {this.state?.newPasswordConfirmationError}
                      </div>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions
                    style={webStyle.submitContainer}
                  >
                    <Button
                      style={webStyle.submitButton}
                      onClick={this.handleResetPasswordSubmit}
                      autoFocus
                    >
                      {this.state?.resetPasswordLoading ?
                        <CircularProgress size={26} color="inherit" />
                      : "Submit"}
                    </Button>
                  </DialogActions>
                </Box>
              </StyledDialog>
              {this.state?.getProfileDataLoading ?
                <Backdrop
                  style={{ zIndex: 1000 + 1, color: "#341f7c" }}
                  open={this.state?.getProfileDataLoading}
                >
                  <CircularProgress color="inherit" />
                </Backdrop> : null
              }
              {this.state?.editProfileDataLoading ?
                <Backdrop
                  style={{ zIndex: 1000 + 1, color: "#341f7c" }}
                  open={this.state?.editProfileDataLoading}
                >
                  <CircularProgress color="inherit" />
                </Backdrop> : null
              }
              {this.state?.tokenExpired === 'Token has Expired' ?
                <SnackbarAlert
                  openSnackbar={this.state?.openSnackbar}
                  handleCloseSnackbar={this.handleCloseSnackbar}
                />
                : null
              }
            </Container>
          </Grid>
        </Grid>
      </Grid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyle = {
  profile: {
    marginTop: "100px",
    fontSize: "24px",
    fontWeight: 'bold' as 'bold',
    color: '#341f7c',
    textAlign: 'center' as 'center',
  },
  gridContainer: {
    minHeight: "100vh",
    paddingBottom: "25px"
  },
  container: {
    marginTop: 30,
    paddingBottom: "30px",
    borderRadius: "16px",
    boxShadow: '2px 2px 15px rgba(52,31,124,0.08)',
    border: "1px solid darkgrey",
    background: '#ffffff',
    maxWidth: "42%",
  },
  expandImage: {
    cursor: 'pointer',
  },
  saveButtonContainer: {
    marginTop: "10%",
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  saveButton: {
    textTransform: 'capitalize' as 'capitalize',
    padding: '8px 0px',
    fontWeight: 400,
    background: '#341f7c',
    borderRadius: '30px',
    width: '180px',
    fontSize: '16px',
  },
  submitButton: {
    marginTop: "-18px",
    textTransform: 'capitalize' as 'capitalize',
    padding: '8px 0px',
    fontWeight: 400,
    borderRadius: '30px',
    width: '130px',
    height: "45px",
    fontSize: '16px',
    background: "#341f7c",
    color: "#fff"
  },
  submitContainer: {
    marginBottom: "5px",
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deactivateConfirmation: {
    maxWidth: "294px",
    color: "#0b0b0b",
    fontSize: "20px",
    lineHeight: "25px",
    textAlign: "center" as "center",
  },
  deactivateButtons: {
    marginTop: 10,
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "space-evenly",
  },
  noButton: {
    textTransform: 'capitalize' as 'capitalize',
    padding: '8px 0px',
    fontWeight: 400,
    borderRadius: '30px',
    width: '130px',
    height: "45px",
    fontSize: '16px',
    border: "2px solid #341f7c"
  },
  yesButton: {
    textTransform: 'capitalize' as 'capitalize',
    padding: '8px 0px',
    fontWeight: 400,
    borderRadius: '30px',
    width: '130px',
    height: "45px",
    fontSize: '16px',
    background: "#341f7c",
    color: "#fff"
  },
  list: {
    padding: 0,
    cursor: 'pointer'
  },
  hrTag: {
    width: '95%',
    border: "0.5px solid #341f7c",
    opacity: "0.08475167"
  },
  resetPasswordBox: {
    padding: "10px 10px",
    width: "342px",
    maxWidth: "100%"
  },
  backIconTitle: {
    marginBottom: "10px",
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  backIcon: {
    cursor: "pointer"
  },
  resetPasswordText: {
    margin: "auto",
    color: "#341f7c",
    fontSize: "18px",
    fontWeight: "bold" as "bold",
  },
  passwordErrorMessage: {
    fontSize: "18px",
    marginTop: 10,
    color: "red",
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "flex-end",
  },
  fullNameBox: {
    height: "35px",
    cursor: 'pointer',
    marginTop: "10px",
    marginBottom: "20px",
  },
  fullNameErrorMessage: {
    color: "red",
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "flex-end",
    marginBottom: 5
  },
};
// Customizable Area End