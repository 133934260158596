export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const Delete = require("../assets/delete.png");

export const hamburgerIcon = require("../assets/hamburgerIcon.png");
export const addaxLogo = require("../assets/addaxLogo.png");
export const profileIcon = require("../assets/profileIcon.png");
export const profile1 = require("../assets/profile1.png");
export const profile2 = require("../assets/profile2.png");
export const expandIcon = require("../assets/expandIcon.png");

export const profile = require("../assets/profile.png");
export const backIcon = require("../assets/backIcon.png");
