import React from "react";
// Customizable Area Start
import {
  Grid,
  Typography,
  Container,
} from "@material-ui/core";
import {
  footerBackground,
  addaxLogoBlack
} from "./assets";
import LandingPageController from "./LandingPageController";
// Customizable Area End

export default class LandingPageFooter extends LandingPageController {

  render() {
    // Customizable Area Start
    return (
      <div style={webStyle.container} id="contact">        
        <Container maxWidth={false} style={{ maxWidth: "81%" }}>
          <Grid container style={webStyle.mainContainer} spacing={2}>
            <Grid
              item
              xs={12}
              sm={6}
              md={1}
              style={webStyle.parentGrids}
            >
              <img
                style={webStyle.addaxLogoBlack}
                src={addaxLogoBlack}
                alt="Logo"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={1}
              style={webStyle.parentGrids}
            >
              <Grid
                item
                style={webStyle.footerColumns}
              >
                <Typography style={webStyle.footerTitle} variant="body1">
                  <b>Home</b>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={1}
              style={webStyle.parentGrids}
            >
              <Grid
                item
                style={webStyle.footerColumns}
              >
                <Typography style={webStyle.footerTitle} variant="body1">
                  <b>Services</b>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={1}
              style={webStyle.parentGrids}
            >
              <Grid
                item
                style={webStyle.footerColumns}
              >
                <Typography style={webStyle.footerTitle} variant="body1">
                  <b>Pricing</b>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={1}
              style={webStyle.parentGrids}
            >
              <Grid
                item
                style={webStyle.footerColumns}
              >
                <Typography style={webStyle.footerTitle} variant="body1">
                  <b>Insights</b>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={1}
              style={webStyle.parentGrids}
            >
              <Grid
                item
                style={webStyle.footerColumns}
              >
                <Typography style={webStyle.footerTitle} variant="body1">
                  <b>Guidance</b>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={1}
              style={webStyle.parentGrids}
            >
              <Grid
                item
                style={webStyle.footerColumns}
              >
                <Typography style={webStyle.footerTitle} variant="body1">
                  <b>Company</b>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={1}
              style={webStyle.parentGrids}
            >
              <Grid
                item
                style={webStyle.footerColumns}
              >
                <Typography style={webStyle.footerTitle} variant="body1">
                  <b>Tools</b>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={1}
              style={webStyle.parentGrids}
            >
              <Grid
                item
                style={webStyle.footerColumns}
              >
                <Typography style={webStyle.footerTitle} variant="body1">
                  <b>Contact</b>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography style={webStyle.footer}>
              &copy; Copyright 2022 &#8226; Addax Company &#8226; All Rights
              Reserved.
            </Typography>
          </Grid>
        </Container>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyle = {
  container: {
    padding: "50px 0px",
    backgroundImage: `url(${footerBackground})`,
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
  },
  mainContainer: {
    padding: "20px 0px 20px 0px",
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  parentGrids: {
    display: "flex",
    justifyContent: "center"
  },
  addaxLogoBlack: {
    height: "auto",
    width: "6.5vw"
  },
  footerColumns: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
  },
  footerTitle: {
    color: "#0b1144",
    fontFamily: "Helvetica",
    fontSize: "1.4vw",
    fontWeight: 500,
    lineHeight: "42px",
    letterSpacing: "-0.92365px",
  },
  footerSubtitle: {
    color: "#0b1144",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "39px",
    letterSpacing: "-0.25px",
  },
  footer: {
    color: "#0b1144",
    fontSize: "0.9vw",
    fontWeight: 400,
    lineHeight: "30px",
    letterSpacing: "-0.546692px",
    textAlign: "center" as "center",
  },
};
// Customizable Area End
